import React, { useContext, useEffect } from "react";
import { Route } from "react-router-dom";

import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";

import Typography from "@material-ui/core/Typography";

import { makeStyles } from "@material-ui/core/styles";
import { Punchs } from "./Punchs";
import { Sidebar } from "./Sidebar";
import { Button, IconButton, Menu, MenuItem } from "@material-ui/core";
import { useKeycloak } from "@react-keycloak/web";
import { DataContext } from "../context/DataContext";
import { AccountCircle } from "@material-ui/icons";
import ImgLogo from "../assets/logo.png";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  title: {
    flexGrow: 1,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export const AppLayout: React.FC = () => {
  const { keycloak } = useKeycloak();
  const classes = useStyles();
  // @ts-ignore
  const [{ username, initSession }] = useContext(DataContext);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (keycloak?.authenticated) {
      keycloak.loadUserProfile().then((user) => initSession(user));
      console.log(keycloak.realm);
    }
  }, []);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar variant={"dense"}>
          <img src={ImgLogo} alt={"Bauunternehmung"} />
          <Typography variant="h6" noWrap className={classes.title}>
            Bauunternehmung
          </Typography>
          {keycloak.authenticated && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div>{username}</div>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={() => keycloak.logout()}>Logout</MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
      <Sidebar />

      <main className={classes.content}>
        <Toolbar variant={"dense"} />
        <Route path={"/app/punchs"} component={Punchs} />
      </main>
    </div>
  );
};
