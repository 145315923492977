import {
  Breadcrumbs,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@material-ui/core";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { DataContext } from "../context/DataContext";
import {
  AutoSizer,
  Column,
  Table,
  TableCellRenderer,
  TableHeaderProps,
} from "react-virtualized";
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import clsx from "clsx";
import { fuzzyMatch, prettifyDate, sec2time } from "../utils/string";
import { DateRangePicker, DateRange } from "materialui-daterange-picker";
import { getFirstDay, getLastDay } from "../utils/date";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import SearchIcon from "@material-ui/icons/Search";
import { FileUploader } from "../components/FileUploader";
import { useSnackbar } from "notistack";
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";
import AddLocationOutlinedIcon from "@material-ui/icons/AddLocationOutlined";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import { ReaderCodeForm } from "../components/ReaderCodeForm";
import { Punch, Reader } from "../types";
import { PunchForm } from "../components/PunchForm";
import SyncOutlinedIcon from "@material-ui/icons/SyncOutlined";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { TimePicker } from "@material-ui/pickers";
import moment from "moment";

declare module "@material-ui/core/styles/withStyles" {
  // Augment the BaseCSSProperties so that we can control jss-rtl
  interface BaseCSSProperties {
    /*
     * Used to control if the rule-set should be affected by rtl transformation
     */
    flip?: boolean;
  }
}

const styles = (theme: Theme) =>
  createStyles({
    flexContainer: {
      display: "flex",
      alignItems: "center",
      boxSizing: "border-box",
    },
    table: {
      // temporary right-to-left patch, waiting for
      // https://github.com/bvaughn/react-virtualized/issues/454
      "& .ReactVirtualized__Table__headerRow": {
        flip: false,
        paddingRight: theme.direction === "rtl" ? "0 !important" : undefined,
      },
    },
    tableRow: {
      cursor: "pointer",
    },
    tableRowHover: {
      "&:hover": {
        backgroundColor: theme.palette.grey[200],
      },
    },
    tableCell: {
      flex: 1,
    },
    noClick: {
      cursor: "initial",
    },
  });

interface ColumnData {
  dataKey: string;
  label: string;
  numeric?: boolean;
  width: number;
  render?: (value: any, row: any) => any;
  flexGrow?: number;
}

interface Row {
  index: number;
}

interface MuiVirtualizedTableProps extends WithStyles<typeof styles> {
  columns: ColumnData[];
  headerHeight?: number;
  onRowClick?: () => void;
  rowCount: number;
  rowGetter: (row: Row) => Data;
  rowHeight?: number;
}

class MuiVirtualizedTable extends React.PureComponent<MuiVirtualizedTableProps> {
  static defaultProps = {
    headerHeight: 48,
    rowHeight: 48,
  };

  getRowClassName = ({ index }: Row) => {
    const { classes, onRowClick } = this.props;

    return clsx(classes.tableRow, classes.flexContainer, {
      [classes.tableRowHover]: index !== -1 && onRowClick != null,
    });
  };

  cellRenderer: TableCellRenderer = ({ cellData, columnIndex, rowData }) => {
    const { columns, classes, rowHeight, onRowClick } = this.props;
    return (
      <TableCell
        component="div"
        className={clsx(classes.tableCell, classes.flexContainer, {
          [classes.noClick]: onRowClick == null,
        })}
        variant="body"
        style={{ height: rowHeight }}
        align={
          (columnIndex != null && columns[columnIndex].numeric) || false
            ? "right"
            : "left"
        }
      >
        {columnIndex != null && columns[columnIndex].render
          ? // @ts-ignore
            columns[columnIndex].render(cellData, rowData)
          : cellData}
      </TableCell>
    );
  };

  headerRenderer = ({
    label,
    columnIndex,
  }: TableHeaderProps & { columnIndex: number }) => {
    const { headerHeight, columns, classes } = this.props;

    return (
      <TableCell
        component="div"
        className={clsx(
          classes.tableCell,
          classes.flexContainer,
          classes.noClick
        )}
        variant="head"
        style={{ height: headerHeight }}
        align={columns[columnIndex].numeric || false ? "right" : "left"}
      >
        <span>{label}</span>
      </TableCell>
    );
  };

  render() {
    const {
      classes,
      columns,
      rowHeight,
      headerHeight,
      ...tableProps
    } = this.props;
    return (
      <AutoSizer>
        {({ height, width }) => (
          <Table
            height={height}
            width={width}
            rowHeight={rowHeight!}
            gridStyle={{
              direction: "inherit",
            }}
            headerHeight={headerHeight!}
            className={classes.table}
            {...tableProps}
            rowClassName={this.getRowClassName}
          >
            {columns.map(({ dataKey, ...other }, index) => {
              return (
                <Column
                  key={dataKey}
                  headerRenderer={(headerProps) =>
                    this.headerRenderer({
                      ...headerProps,
                      columnIndex: index,
                    })
                  }
                  className={classes.flexContainer}
                  cellRenderer={this.cellRenderer}
                  dataKey={dataKey}
                  {...other}
                />
              );
            })}
          </Table>
        )}
      </AutoSizer>
    );
  }
}

const VirtualizedTable = withStyles(styles)(MuiVirtualizedTable);

interface Data {
  calories: number;
  carbs: number;
  dessert: string;
  fat: number;
  id: number;
  protein: number;
}

const useStyles = makeStyles((theme) => ({
  filterIcon: {
    marginRight: "1rem",
  },
  filterContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0.5rem",
  },
  filterField: {
    width: "150px",
  },
  filterSeparator: {
    marginLeft: "1rem",
    marginRight: "1rem",
  },
}));

export const Punchs: React.FC = () => {
  // @ts-ignore
  const [
    {
      fetchPunchs,
      punchs,
      isFetchingPunchs,
      isUploadingPunchsFile,
      uploadPunchsFile,
      isSubmittingReader,
      setReader,
      readers,
      fetchReaders,
      addPunch,
      updatePunch,
      deletePunch,
    },
  ] = useContext(DataContext);
  const { enqueueSnackbar } = useSnackbar();
  const [isModalPickerOpen, setIsModalPickerOpen] = useState(false);
  const [isModalUploadOpen, setIsModalUploadOpen] = useState(false);
  const [isModalReaderCodeOpen, setIsModalReaderCodeOpen] = useState(false);
  const [isModalPunchOpen, setIsModalPunchOpen] = useState(false);
  const [dateRange, setDateRange] = React.useState<DateRange>({
    startDate: getFirstDay(),
    endDate: getLastDay(),
  });
  const classes = useStyles();
  const [queryBadge, setQueryBadge] = useState("");
  const [queryPunchType, setQueryPunchType] = useState("");
  const [queryStartTime, setQueryStartTime] = useState<any | null>(null);
  const [queryEndTime, setQueryEndTime] = useState<any | null>(null);
  const [currentPunch, setCurrentPunch] = useState(null);

  const filteredPunchs = useMemo(() => {
    let filteredData = punchs;

    if (queryBadge.length > 0) {
      filteredData = filteredData.filter((punch: Punch) =>
        fuzzyMatch(punch.idBadge, queryBadge)
      );
    }

    if (queryPunchType.length > 0) {
      filteredData = filteredData.filter(
        (punch: Punch) =>
          punch.readerCodeId === queryPunchType ||
          fuzzyMatch(punch.reader?.description || "", queryPunchType)
      );
    }

    if (queryStartTime) {
      const minutesFromMidnight =
        queryStartTime.hours() * 60 + queryStartTime.minute();
      filteredData = filteredData.filter((punch: Punch) => {
        return (
          (punch.punchHourFIn && punch.punchHourFIn >= minutesFromMidnight) ||
          (punch.punchHourFOut && punch.punchHourFOut >= minutesFromMidnight)
        );
      });
    }

    if (queryEndTime) {
      const minutesFromMidnight =
        queryEndTime.hours() * 60 + queryEndTime.minute();
      filteredData = filteredData.filter((punch: Punch) => {
        return (
          (punch.punchHourFIn && punch.punchHourFIn <= minutesFromMidnight) ||
          (punch.punchHourFOut && punch.punchHourFOut <= minutesFromMidnight)
        );
      });
    }

    return filteredData;
  }, [punchs, queryBadge, queryPunchType, queryStartTime, queryEndTime]);

  const totalWorkLog = useMemo(() => {
    return queryBadge.length > 0 || queryPunchType.length > 0
      ? filteredPunchs.reduce(
          (workLogs: number, punch: Punch) =>
            (workLogs +=
              punch.punchHourVIn && punch.punchHourVOut
                ? punch.punchHourVOut - punch.punchHourVIn
                : 0),
          0
        )
      : null;
  }, [filteredPunchs, queryBadge, queryPunchType]);

  useEffect(() => {
    handleFetchPunchs();
  }, []);

  const handleFetchPunchs = useCallback(() => {
    fetchPunchs(dateRange.startDate, dateRange.endDate);
  }, [fetchPunchs, dateRange]);

  const handleUploadPunchFile = useCallback(
    async (file: File) => {
      try {
        await uploadPunchsFile(file);
        enqueueSnackbar("Caricamento eseguito con successo!", {
          variant: "success",
        });
      } catch (err) {
        enqueueSnackbar(
          "Qualcosa è andato storto. Assicurati di essere connesso ad internet e che il file sia corretto!",
          { variant: "error" }
        );
      }
      setIsModalUploadOpen(false);
    },
    [uploadPunchsFile, enqueueSnackbar, setIsModalUploadOpen]
  );

  const handleSubmitReader = useCallback(
    async (reader: Reader) => {
      try {
        await setReader(reader);
        enqueueSnackbar("Operazione eseguita con successo!", {
          variant: "success",
        });
      } catch (err) {
        enqueueSnackbar(
          "Qualcosa è andato storto. Assicurati di essere connesso ad internet!",
          { variant: "error" }
        );
      }
      setIsModalReaderCodeOpen(false);
    },
    [setIsModalReaderCodeOpen, setReader, enqueueSnackbar]
  );

  const handeSubmitPunch = useCallback(
    async (punch) => {
      try {
        if (punch.id) {
          await updatePunch(punch);
        } else {
          await addPunch(punch);
        }
        enqueueSnackbar("Operazione eseguita con successo!", {
          variant: "success",
        });
      } catch (err) {
        enqueueSnackbar(
          "Qualcosa è andato storto. Assicurati di essere connesso ad internet!",
          { variant: "error" }
        );
      }
      setIsModalPunchOpen(false);
    },
    [addPunch, updatePunch, setIsModalPunchOpen, enqueueSnackbar]
  );

  const handleEditPunch = useCallback(
    (punch) => {
      setCurrentPunch(punch);
      setIsModalPunchOpen(true);
    },
    [setCurrentPunch, setIsModalPunchOpen]
  );

  const handleDeletePunch = useCallback(
    async (punch) => {
      if (window.confirm("Confermi di voler cancellare la timbratura?")) {
        try {
          await deletePunch(punch);

          enqueueSnackbar("Operazione eseguita con successo!", {
            variant: "success",
          });
        } catch (err) {
          enqueueSnackbar(
            "Qualcosa è andato storto. Assicurati di essere connesso ad internet!",
            { variant: "error" }
          );
        }
        setCurrentPunch(null);
        setIsModalPunchOpen(false);
      }
    },
    [deletePunch, setCurrentPunch, setIsModalPunchOpen, enqueueSnackbar]
  );

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ flexGrow: 1 }}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link color={"textPrimary"} aria-current="page">
              Timbrature
            </Link>
          </Breadcrumbs>
        </div>
        <div>
          {isFetchingPunchs && <CircularProgress size={"1.5rem"} />}
          <Button
            color="secondary"
            onClick={() => setIsModalReaderCodeOpen(true)}
            startIcon={<AddLocationOutlinedIcon />}
            style={{ marginRight: "1rem" }}
          >
            Identifica timbratura
          </Button>

          <Button
            color="secondary"
            onClick={() => setIsModalUploadOpen(true)}
            startIcon={<CloudUploadOutlinedIcon />}
            style={{ marginRight: "1rem" }}
          >
            Carica
          </Button>

          <Button
            color="primary"
            onClick={() => setIsModalPunchOpen(true)}
            startIcon={<AddOutlinedIcon />}
          >
            Aggiungi
          </Button>
        </div>
      </div>

      <Paper style={{ marginTop: "1.5rem" }}>
        <div className={classes.filterContainer}>
          <SearchIcon className={classes.filterIcon} />

          <FormControl size={"small"} variant={"outlined"}>
            <InputLabel htmlFor={"filter-badge"}>Badge</InputLabel>
            <Input
              id="filter-badge"
              type={"text"}
              value={queryBadge}
              onChange={(e) => setQueryBadge(e.target.value)}
              style={{ marginRight: "1rem" }}
              endAdornment={
                queryBadge.length > 0 && (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="cancel badge filter"
                      onClick={(e) => setQueryBadge("")}
                      onMouseDown={(e) => setQueryBadge("")}
                    >
                      <HighlightOffIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }
            />
          </FormControl>

          <FormControl size={"small"} variant={"outlined"}>
            <InputLabel htmlFor={"filter-punch-type"}>Timbratura</InputLabel>
            <Input
              id={"filter-punch-type"}
              type={"text"}
              value={queryPunchType}
              onChange={(e) => setQueryPunchType(e.target.value)}
              style={{ marginRight: "1rem" }}
              endAdornment={
                queryPunchType.length > 0 && (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="cancel punch type filter"
                      onClick={(e) => setQueryPunchType("")}
                      onMouseDown={(e) => setQueryPunchType("")}
                    >
                      <HighlightOffIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }
            />
          </FormControl>

          <FormControl size={"small"} variant={"outlined"}>
            <div>
              <TimePicker
                clearable
                ampm={false}
                label={"Da ora"}
                value={queryStartTime}
                onChange={setQueryStartTime}
                style={{ width: "6rem", marginRight: "0.5rem" }}
              />
              <TimePicker
                clearable
                ampm={false}
                label={"A ora"}
                value={queryEndTime}
                onChange={setQueryEndTime}
                style={{ width: "6rem" }}
              />
              {(queryStartTime || queryEndTime) && (
                <IconButton
                  aria-label="cancel time filter"
                  onClick={(e) => {
                    setQueryStartTime(null);
                    setQueryEndTime(null);
                  }}
                  onMouseDown={(e) => {
                    setQueryStartTime(null);
                    setQueryEndTime(null);
                  }}
                >
                  <HighlightOffIcon />
                </IconButton>
              )}
            </div>
          </FormControl>

          <div style={{ flexGrow: 1 }} />
          <Button onClick={() => setIsModalPickerOpen(true)}>
            <span style={{ marginRight: "0.5rem" }}>
              {`${prettifyDate(dateRange.startDate)} - ${prettifyDate(
                dateRange.endDate
              )}`}
            </span>
            <CalendarTodayIcon />
          </Button>
          <IconButton
            color={"primary"}
            aria-label={"refresh"}
            onClick={handleFetchPunchs}
          >
            <SyncOutlinedIcon />
          </IconButton>
        </div>
      </Paper>

      <Paper style={{ marginTop: "2rem", width: "100%", height: "400px" }}>
        <VirtualizedTable
          rowCount={filteredPunchs.length}
          rowGetter={({ index }) => filteredPunchs[index]}
          columns={[
            {
              width: 80,
              flexGrow: 1,
              label: "ID",
              dataKey: "id",
              render: (value, punch) => (
                <Link
                  href={"#"}
                  onClick={(e: React.SyntheticEvent) => {
                    e.preventDefault();
                    handleEditPunch(punch);
                  }}
                >
                  {value}
                </Link>
              ),
            },
            {
              width: 120,
              flexGrow: 1,
              label: "Badge",
              dataKey: "idBadge",
            },
            {
              width: 120,
              flexGrow: 1,
              label: "Data E",
              dataKey: "punchDateIn",
              render: (value, punch) => prettifyDate(value),
            },
            {
              width: 120,
              flexGrow: 1,
              label: "Ora E",
              dataKey: "punchHourFIn",
              render: (value, punch) => sec2time(value * 60, "HH:MM"),
            },
            {
              width: 140,
              flexGrow: 1,
              label: "Ora formale E",
              dataKey: "punchHourVIn",
              render: (value, punch) => sec2time(value * 60, "HH:MM"),
            },
            {
              width: 120,
              flexGrow: 1,
              label: "Data U",
              dataKey: "punchDateOut",
              render: (value, punch) => (value ? prettifyDate(value) : "-"),
            },
            {
              width: 120,
              flexGrow: 1,
              label: "Ora U",
              dataKey: "punchHourFOut",
              render: (value, punch) =>
                value ? sec2time(value * 60, "HH:MM") : "-",
            },
            {
              width: 140,
              flexGrow: 1,
              label: "Ora formale U",
              dataKey: "punchHourVOut",
              render: (value, punch) =>
                value ? sec2time(value * 60, "HH:MM") : "",
            },
            {
              width: 140,
              flexGrow: 1,
              label: "Timbratura",
              dataKey: "reader",
              render: (reader, punch) =>
                reader?.code
                  ? `${reader.code} - ${reader.description}`
                  : reader,
            },
            {
              width: 140,
              flexGrow: 1,
              label: "Ore lavorate",
              dataKey: "",
              render: (_, punch: Punch) =>
                punch.punchHourVOut && punch.punchHourVIn
                  ? sec2time(
                      (punch.punchHourVOut - punch.punchHourVIn) * 60,
                      "HH:MM"
                    )
                  : "-",
            },
          ]}
        />
      </Paper>
      {totalWorkLog && (
        <Paper
          style={{
            marginTop: "0.5rem",
            width: "100%",
            display: "flex",
            padding: "0.5rem",
            justifyContent: "flex-end",
            paddingRight: "5rem",
          }}
        >
          <div>
            <span style={{ fontWeight: 600, marginRight: "1rem" }}>
              Totale ore lavorate:{" "}
            </span>
            {sec2time(totalWorkLog * 60, "HH:MM")}
          </div>
        </Paper>
      )}
      {/* Period Picker */}
      <Dialog
        open={isModalPickerOpen}
        onClose={() => setIsModalPickerOpen(false)}
        aria-labelledby={"modal-period-picker"}
        maxWidth={"lg"}
      >
        <DialogTitle id={"modal-period-picker"}>
          Seleziona il periodo di visualizzazione
        </DialogTitle>
        <DialogContent>
          <DateRangePicker
            open={true}
            toggle={() => null}
            onChange={(range) => setDateRange(range)}
          />
          <DialogActions>
            <Button
              onClick={() => {
                setIsModalPickerOpen(false);
                handleFetchPunchs();
              }}
              color={"primary"}
            >
              Conferma
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      {/* Upload file */}
      <Dialog
        open={isModalUploadOpen}
        onClose={() => setIsModalUploadOpen(false)}
        aria-labelledby={"form-file-upload"}
      >
        <DialogTitle id={"form-file-upload"}>
          Caricamento foglio ore
        </DialogTitle>
        <DialogContent>
          <FileUploader
            onCancel={() => setIsModalUploadOpen(false)}
            onConfirm={(file) => handleUploadPunchFile(file)}
            isUploading={isUploadingPunchsFile}
          />
        </DialogContent>
      </Dialog>
      {/* ReaderCodeForm */}
      <Dialog
        open={isModalReaderCodeOpen}
        onClose={() => setIsModalReaderCodeOpen(false)}
        aria-labelledby={"form-reader-code"}
      >
        <DialogTitle id={"form-reader-code"}>
          Identificazione timbratura
        </DialogTitle>
        <DialogContent>
          <ReaderCodeForm
            reader={null}
            onCancel={() => setIsModalReaderCodeOpen(false)}
            onSubmit={handleSubmitReader}
            isSubmitting={isSubmittingReader}
          />
        </DialogContent>
      </Dialog>
      {/* PunchForm */}

      <Dialog
        open={isModalPunchOpen}
        onClose={() => {
          setIsModalPunchOpen(false);
          setCurrentPunch(null);
        }}
        aria-labelledby={"form-punch"}
      >
        <DialogTitle id={"form-punch"}>Timbratura</DialogTitle>
        <DialogContent>
          <PunchForm
            punch={currentPunch}
            readers={readers}
            onCancel={() => {
              setIsModalPunchOpen(false);
              setCurrentPunch(null);
            }}
            isSubmitting={false}
            onSubmit={handeSubmitPunch}
            onLoad={fetchReaders}
            onDelete={handleDeletePunch}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};
