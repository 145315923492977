import React, { useCallback } from "react";
import Form from "@rjsf/material-ui";
import {
  Button,
  CircularProgress,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { Reader } from "../types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      display: "none",
    },
    filePickerButton: {
      width: "100%",
      marginTop: "1rem",
    },
    actions: {
      display: "flex",
      marginTop: "1rem",
    },
    actionLeft: {
      flexGrow: 1,
    },
  })
);

type Props = {
  onSubmit: (reader: Reader) => any;
  onCancel: () => any;
  isSubmitting: boolean;
  reader: Reader | null;
};

export const ReaderCodeForm: React.FC<Props> = ({
  reader,
  onCancel,
  onSubmit,
  isSubmitting,
}) => {
  const classes = useStyles();

  const schema = {
    type: "object",
    required: ["code"],
    properties: {
      code: { type: "string", title: "Codice timbratura" },
      description: { type: "string", title: "Descrizione" },
    },
  };

  const handleSubmit = useCallback(
    (form) => {
      if (form.errors.length === 0) {
        onSubmit(form.formData);
      }
    },
    [onSubmit]
  );

  return (
    <Form
      // @ts-ignore
      schema={schema}
      formData={reader}
      onSubmit={handleSubmit}
    >
      <div className={classes.actions}>
        <div className={classes.actionLeft}>
          <Button
            color={"secondary"}
            disabled={isSubmitting}
            onClick={onCancel}
          >
            Annulla
          </Button>
        </div>
        {isSubmitting && <CircularProgress size={"1rem"} />}
        {
          <Button
            variant={"contained"}
            type={"submit"}
            color={"primary"}
            disableElevation
            disabled={isSubmitting}
          >
            Conferma
          </Button>
        }
      </div>
    </Form>
  );
};
