import config from "../config/config";
import { Punch, Reader } from "../types";
import { toPunch, toReader } from "./utils";

const makeApiRequest = async (
  method: "POST" | "GET" | "PATCH" | "DELETE",
  path: string,
  body?: any
): Promise<any> => {
  const headers = new Headers({
    Accept: "application/json",
    "Content-Type": "application/json",
  });

  try {
    const response = await fetch(`${config.BACKEND_API_URL}${path}`, {
      method: method,
      body: body ? JSON.stringify(body) : undefined,
      headers: headers,
    });

    if (!response.ok) {
      const message = await parseErrorMessage(response);
      throw new Error(`${response.status}: ${response.statusText}. ${message}`);
    }
    const responseJson = await response.json();
    return responseJson;
  } catch (err) {
    console.warn(`Failed request ${method} ${path}`, err);
    throw err;
  }
};

const parseErrorMessage = async (response: Response) => {
  try {
    const data = await response.json();
    if (!data.ErrorMessage) {
      throw new Error();
    }
    return data.ErrorMessage;
  } catch (err) {
    return "";
  }
};

const fetchPunchs = async (
  startDate: string,
  endDate: string
): Promise<Punch> => {
  const response = await makeApiRequest(
    "GET",
    `punch?start_date=${startDate}&end_date=${endDate}`
  );
  return response.map((item: any) => toPunch(item));
};

const addPunch = async (
  idBadge: Punch["idBadge"],
  punchDateIn: Punch["punchDateIn"],
  punchDateOut: Punch["punchDateOut"],
  punchHourFIn: Punch["punchHourFIn"],
  punchHourVIn: Punch["punchHourVIn"],
  punchHourFOut: Punch["punchHourFOut"],
  punchHourVOut: Punch["punchHourVOut"],
  readerCodeId: Punch["readerCodeId"],
  note: Punch["note"]
) => {
  const payload = {
    id_badge: idBadge,
    punch_date_in: punchDateIn,
    punch_date_out: punchDateOut,
    punch_hour_f_in: punchHourFIn,
    punch_hour_v_in: punchHourVIn,
    punch_hour_f_out: punchHourFOut,
    punch_hour_v_out: punchHourVOut,
    reader_code_id: readerCodeId,
    note,
  };

  const response = await makeApiRequest("POST", "punch", payload);

  return response;
};

const updatePunch = async (
  id: Punch["id"],
  idBadge: Punch["idBadge"],
  punchDateIn: Punch["punchDateIn"],
  punchDateOut: Punch["punchDateOut"],
  punchHourFIn: Punch["punchHourFIn"],
  punchHourVIn: Punch["punchHourVIn"],
  punchHourFOut: Punch["punchHourFOut"],
  punchHourVOut: Punch["punchHourVOut"],
  readerCodeId: Punch["readerCodeId"],
  note: Punch["note"]
) => {
  const payload = {
    id_badge: idBadge,
    punch_date_in: punchDateIn,
    punch_date_out: punchDateOut,
    punch_hour_f_in: punchHourFIn,
    punch_hour_v_in: punchHourVIn,
    punch_hour_f_out: punchHourFOut,
    punch_hour_v_out: punchHourVOut,
    reader_code_id: readerCodeId,
    note,
  };

  const response = await makeApiRequest("PATCH", `punch/${id}`, payload);

  return response;
};

const deletePunch = async (id: Punch["id"]) => {
  const response = await makeApiRequest("DELETE", `punch/${id}`);

  return response;
};

const uploadPunchFile = async (file: File) => {
  let formData = new FormData();

  formData.append("file", file);

  try {
    const response = await fetch(`${config.BACKEND_API_URL}punch/upload`, {
      method: "POST",
      body: formData,
    });

    if (!response.ok) {
      const message = await parseErrorMessage(response);
      throw new Error(`${response.status}: ${response.statusText}. ${message}`);
    }
    const responseJson = await response.json();
    return responseJson;
  } catch (err) {
    console.warn(`Failed request`, err);
    throw err;
  }
};

const fetchReaders = async () => {
  const response = await makeApiRequest("GET", "reader");

  return response.map((item: any) => toReader(item));
};

const setReader = async (
  code: Reader["code"],
  description: Reader["description"]
) => {
  const payload = {
    code,
    description,
  };

  const response = await makeApiRequest("POST", "reader", payload);

  return response;
};

const api = {
  fetchPunchs,
  addPunch,
  updatePunch,
  deletePunch,
  uploadPunchFile,
  fetchReaders,
  setReader,
};

export default api;
