import React from "react";
import { HashRouter as Router, Redirect, Route } from "react-router-dom";
import { DataProvider } from "../context/DataContext";
import { AppLayout } from "./AppLayout";
import { SnackbarProvider } from "notistack";
import { Login } from "./Login";

import { ReactKeycloakProvider, useKeycloak } from "@react-keycloak/web";
import { PrivateRoute } from "./PrivateRoute";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#CB5047",
    },
  },
});

export const Root: React.FC = () => {
  const { initialized } = useKeycloak();

  if (!initialized) {
    return <div>Loading...</div>;
  }

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DataProvider>
            <Router>
              <Route path={"/login"} component={Login} />
              <Route
                exact
                path={"/"}
                component={() => <Redirect to={"/app/punchs"} />}
              />
              <PrivateRoute
                roles={["admin"]}
                path={"/app"}
                component={AppLayout}
              />
            </Router>
          </DataProvider>
        </MuiPickersUtilsProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
};
