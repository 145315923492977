import React, { useCallback, useEffect } from "react";
import Form from "@rjsf/material-ui";
import {
  Button,
  CircularProgress,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { Punch, Reader } from "../types";
import { prettifyDate } from "../utils/string";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      display: "none",
    },
    filePickerButton: {
      width: "100%",
      marginTop: "1rem",
    },
    actions: {
      display: "flex",
      marginTop: "1rem",
    },
    actionLeft: {
      flexGrow: 1,
    },
  })
);

type Props = {
  onSubmit: (punch: any) => any;
  onCancel: () => any;
  onDelete: (punch: any) => any;
  isSubmitting: boolean;
  punch: Punch | null;
  onLoad: () => any;
  readers: Reader[];
};

export const PunchForm: React.FC<Props> = ({
  punch,
  onCancel,
  onSubmit,
  onDelete,
  isSubmitting,
  onLoad,
  readers,
}) => {
  const classes = useStyles();

  const schema = {
    type: "object",
    required: ["idBadge", "punchDatetimeIn", "punchDatetimeOut"],
    properties: {
      idBadge: { type: "string", title: "Badge" },
      punchDatetimeIn: {
        title: "Data e ora ingresso",
        type: "string",
        format: "date-time",
      },
      punchFormalDatetimeIn: {
        title: "Data e ora ingresso formale",
        type: "string",
        format: "date-time",
      },
      punchDatetimeOut: {
        title: "Data e ora uscita",
        type: "string",
        format: "date-time",
      },
      punchFormalDatetimeOut: {
        title: "Data e ora uscita formale",
        type: "string",
        format: "date-time",
      },
      readerCodeId: {
        title: "Timbratura",
        type: "string",
        enum: readers.map((reader) => reader.code),
        enumNames: readers.map((reader) => reader.description),
      },
      note: {
        title: "Note",
        type: "string",
      },
    },
  };
  const uiSchema = {
    note: {
      "ui:widget": "textarea",
    },
  };

  const toDateTime = (date: Date, minutesFromMidnight: number) => {
    return new Date(date.getTime() + minutesFromMidnight * 60000).toISOString();
  };

  const formData = {
    idBadge: punch?.idBadge,
    readerCodeId: punch?.readerCodeId || undefined,
    note: punch?.note,
    punchDatetimeIn: punch
      ? toDateTime(punch.punchDateIn, punch.punchHourFIn)
      : null,
    punchFormalDatetimeIn: punch
      ? toDateTime(punch.punchDateIn, punch.punchHourVIn)
      : null,
    punchDatetimeOut: punch?.punchDateOut
      ? toDateTime(punch.punchDateOut, punch.punchHourFOut)
      : null,
    punchFormalDatetimeOut: punch?.punchDateOut
      ? toDateTime(punch.punchDateOut, punch.punchHourVOut)
      : null,
  };

  useEffect(() => {
    onLoad();
  }, []);

  const handleSubmit = useCallback(
    (form) => {
      if (form.errors.length === 0) {
        const {
          idBadge,
          punchDatetimeIn,
          punchFormalDatetimeIn,
          punchDatetimeOut,
          punchFormalDatetimeOut,
          readerCodeId,
          note,
        } = form.formData;

        const parsedPunchDateTimeIn = new Date(punchDatetimeIn);
        const parsedFormalPunchDateTimeIn = new Date(punchFormalDatetimeIn);
        const punchDayIn = new Date(
          parsedPunchDateTimeIn.getFullYear(),
          parsedPunchDateTimeIn.getMonth(),
          parsedPunchDateTimeIn.getDate(),
          0,
          0,
          0
        );
        const parsedPunchDateTimeOut = new Date(punchDatetimeOut);
        const parsedFormalPunchDateTimeOut = new Date(punchFormalDatetimeOut);
        const punchDayOut = new Date(
          parsedPunchDateTimeOut.getFullYear(),
          parsedPunchDateTimeOut.getMonth(),
          parsedPunchDateTimeOut.getDate(),
          0,
          0,
          0
        );

        const punchDateInMinSinceMidnight =
          // @ts-ignore
          (parsedPunchDateTimeIn - punchDayIn) / 60000;
        const formalPunchDateInMinSinceMidnight =
          // @ts-ignore
          (parsedFormalPunchDateTimeIn - punchDayIn) / 60000;

        const punchDateOutMinSinceMidnight =
          // @ts-ignore
          (parsedPunchDateTimeOut - punchDayOut) / 60000;
        const formalPunchDateOutMinSinceMidnight =
          // @ts-ignore
          (parsedFormalPunchDateTimeOut - punchDayOut) / 60000;

        const editedPunch: any = {
          idBadge,
          punchDateIn: prettifyDate(punchDatetimeIn, "YYYY-MM-DD"),
          punchDateOut: prettifyDate(punchDatetimeOut, "YYYY-MM-DD"),
          punchHourFIn: punchDateInMinSinceMidnight,
          punchHourVIn: formalPunchDateInMinSinceMidnight,
          punchHourFOut: punchDateOutMinSinceMidnight,
          punchHourVOut: formalPunchDateOutMinSinceMidnight,
          readerCodeId: readerCodeId || null,
          note: note || null,
        };

        if (punch) {
          editedPunch["id"] = punch.id;
        }

        onSubmit(editedPunch);
      }
    },
    [onSubmit, punch]
  );

  return (
    <Form
      // @ts-ignore
      schema={schema}
      uiSchema={uiSchema}
      formData={formData}
      onSubmit={handleSubmit}
    >
      <div className={classes.actions}>
        <div className={classes.actionLeft}>
          <Button disabled={isSubmitting} onClick={onCancel}>
            Annulla
          </Button>
        </div>
        {isSubmitting && <CircularProgress size={"1rem"} />}
        {punch?.id && (
          <Button
            type={"button"}
            color={"secondary"}
            disabled={isSubmitting}
            onClick={() => onDelete(punch)}
          >
            Elimina
          </Button>
        )}
        <Button
          variant={"contained"}
          type={"submit"}
          color={"primary"}
          disableElevation
          disabled={isSubmitting}
        >
          Conferma
        </Button>
      </div>
    </Form>
  );
};
