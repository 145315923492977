import React from "react";
import api from "../services/api";
import { Reader } from "../types";
import { prettifyDate } from "../utils/string";

type ContextProps = {
  punchs: any[];
  readers: Reader[];
  isFetchingPunchs: boolean;
  fetchPunchs: () => void;
  isUploadingPunchsFile: boolean;
  isSubmittingPunch: boolean;
  uploadPunchsFile: (file: File) => void;
  isSubmittingReader: boolean;
  setReader: (reader: Reader) => void;
  isFetchingReaders: boolean;
  fetchReaders: () => void;
  username: string | null;
};

let initialState = {
  punchs: [],
  readers: [],
  isFetchingPunchs: false,
  isUploadingPunchsFile: false,
  isFetchingReaders: false,
  isSubmittingReader: false,
  isSubmittingPunch: false,
  username: null,
};

const DataContext = React.createContext<Partial<ContextProps>>({});

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case "init_session":
      return {
        ...state,
        username: action.payload.username,
      };
    case "fetching_punchs":
      return {
        ...state,
        isFetchingPunchs: true,
      };
    case "punchs_loaded":
      return {
        ...state,
        isFetchingPunchs: false,
        punchs: action.payload.punchs,
      };
    case "submitting_punch":
      return {
        ...state,
        isSubmittingPunch: true,
      };
    case "submitted_punch":
      return {
        ...state,
        isSubmittingPunch: false,
      };
    case "uploading_punchs":
      return {
        ...state,
        isUploadingPunchsFile: true,
      };
    case "uploaded_punchs":
      return {
        ...state,
        isUploadingPunchsFile: false,
      };
    case "fetching_readers":
      return {
        ...state,
        isFetchingReaders: true,
      };
    case "readers_loaded":
      return {
        ...state,
        isFetchingReaders: false,
        readers: action.payload.readers,
      };
    case "updating_reader":
      return {
        ...state,
        isSubmittingReader: true,
      };
    case "updated_reader":
      return {
        ...state,
        isSubmittingReader: false,
      };
    default:
      return { ...state };
  }
};

const DataProvider = (props: any) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const initSession = React.useCallback(
    (user) => {
      console.log(user);
      dispatch({
        type: "init_session",
        payload: {
          username: user.username,
        },
      });
    },
    [dispatch]
  );

  const fetchPunchs = React.useCallback(
    async (startDate: Date, endDate: Date) => {
      dispatch({
        type: "fetching_punchs",
      });

      const punchs = await api.fetchPunchs(
        prettifyDate(startDate, "YYYY-MM-DD"),
        prettifyDate(endDate, "YYYY-MM-DD")
      );

      dispatch({
        type: "punchs_loaded",
        payload: {
          punchs,
        },
      });
    },
    [dispatch]
  );

  const addPunch = React.useCallback(
    async (punch: any) => {
      dispatch({
        type: "submitting_punch",
      });

      try {
        await api.addPunch(
          punch.idBadge,
          punch.punchDateIn,
          punch.punchDateOut,
          punch.punchHourFIn,
          punch.punchHourVIn,
          punch.punchHourFOut,
          punch.punchHourVOut,
          punch.readerCodeId,
          punch.note
        );
      } catch (err) {
        console.log(err);
        throw err;
      } finally {
        dispatch({
          type: "submitted_punch",
        });
      }
    },
    [dispatch]
  );

  const updatePunch = React.useCallback(
    async (punch: any) => {
      dispatch({
        type: "submitting_punch",
      });

      try {
        await api.updatePunch(
          punch.id,
          punch.idBadge,
          punch.punchDateIn,
          punch.punchDateOut,
          punch.punchHourFIn,
          punch.punchHourVIn,
          punch.punchHourFOut,
          punch.punchHourVOut,
          punch.readerCodeId,
          punch.note
        );
      } catch (err) {
        console.log(err);
        throw err;
      } finally {
        dispatch({
          type: "submitted_punch",
        });
      }
    },
    [dispatch]
  );

  const deletePunch = React.useCallback(
    async (punch: any) => {
      dispatch({
        type: "submitting_punch",
      });

      try {
        await api.deletePunch(punch.id);
      } catch (err) {
        console.log(err);
        throw err;
      } finally {
        dispatch({
          type: "submitted_punch",
        });
      }
    },
    [dispatch]
  );

  const uploadPunchsFile = React.useCallback(
    async (file: File) => {
      dispatch({
        type: "uploading_punchs",
      });

      try {
        await api.uploadPunchFile(file);
      } catch (err) {
        console.log(err);
        throw err;
      } finally {
        dispatch({
          type: "uploaded_punchs",
        });
      }
    },
    [dispatch]
  );

  const fetchReaders = React.useCallback(async () => {
    dispatch({
      type: "fetching_readers",
    });

    let readers: Reader[] = [];

    try {
      readers = await api.fetchReaders();
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      dispatch({
        type: "readers_loaded",
        payload: {
          readers,
        },
      });
    }
  }, [dispatch]);

  const setReader = React.useCallback(
    async (reader: Reader) => {
      dispatch({
        type: "updating_reader",
      });

      try {
        await api.setReader(reader.code, reader.description);
      } catch (err) {
        console.log(err);
        throw err;
      } finally {
        dispatch({
          type: "updated_reader",
        });
      }
    },
    [dispatch]
  );

  const value: any = [
    {
      ...state,
      initSession,
      fetchPunchs,
      uploadPunchsFile,
      setReader,
      fetchReaders,
      addPunch,
      updatePunch,
      deletePunch,
    },
    dispatch,
  ];

  return (
    <DataContext.Provider value={value}>{props.children}</DataContext.Provider>
  );
};

export { DataContext, DataProvider };
