import dayjs from "dayjs";

export const prettifyDate = (v: any, format = "DD-MM-YYYY"): string => {
  const parsed = dayjs(v);
  return !v || !parsed.isValid() ? "" : parsed.format(format);
};

export const sec2time = (
  timeInSeconds: number,
  format: "HH:MM:SS" | "HH:MM" = "HH:MM:SS"
) => {
  var pad = function (num: any, size: any) {
      return ("000" + num).slice(size * -1);
    },
    time: any = parseFloat(timeInSeconds.toString()).toFixed(3),
    hours: number = Math.floor(time / 60 / 60),
    minutes: number = Math.floor(time / 60) % 60,
    seconds: number = Math.floor(time - minutes * 60);

  return format === "HH:MM:SS"
    ? (hours < 99 ? pad(hours, 2) : hours) +
        ":" +
        pad(minutes, 2) +
        ":" +
        pad(seconds, 2)
    : (hours < 99 ? pad(hours, 2) : hours) + ":" + pad(minutes, 2);
};

export const fuzzyMatch = (text: string, searchToken: string): boolean => {
  const normalizedText = String(text).trim().toLowerCase();
  const normalizedSearchToken = String(searchToken).trim().toLowerCase();
  return normalizedText.includes(normalizedSearchToken);
};
