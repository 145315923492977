import {
  Button,
  CircularProgress,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core";
import React, { useRef, useCallback, useEffect, useState } from "react";

type Props = {
  onConfirm: (file: File) => any;
  onCancel: () => any;
  isUploading: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      display: "none",
    },
    filePickerButton: {
      width: "100%",
      marginTop: "1rem",
    },
    actions: {
      display: "flex",
      marginTop: "1rem",
    },
    actionLeft: {
      flexGrow: 1,
    },
  })
);

export const FileUploader: React.FC<Props> = ({
  onCancel,
  onConfirm,
  isUploading,
}) => {
  const classes = useStyles();
  const [file, setFile] = useState<File | null>(null);

  useEffect(() => {
    return () => setFile(null);
  }, []);

  const handleFileInput = useCallback(
    (e) => {
      // handle validations
      setFile(e.target.files[0]);
    },
    [setFile]
  );

  return (
    <div className={"file-uploader"}>
      <div>Selezionare il file Excel (.xlsx) da caricare:</div>
      <input
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        className={classes.input}
        id="upload-button-file"
        type="file"
        onChange={handleFileInput}
      />
      <label htmlFor={"upload-button-file"}>
        <Button
          color={"primary"}
          component={"span"}
          className={classes.filePickerButton}
          disabled={isUploading}
        >
          {file ? file.name : "Seleziona file"}
        </Button>
      </label>
      <div className={classes.actions}>
        <div className={classes.actionLeft}>
          <Button color={"secondary"} disabled={isUploading} onClick={onCancel}>
            Cancella
          </Button>
        </div>
        {isUploading && <CircularProgress size={"1rem"} />}
        {file && (
          <Button
            variant={"contained"}
            color={"primary"}
            disableElevation
            onClick={() => onConfirm(file)}
            disabled={isUploading}
          >
            Carica
          </Button>
        )}
      </div>
    </div>
  );
};
