import Keycloak from "keycloak-js";
//const env = Object.assign({}, process.env);

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
const keycloak = Keycloak({
  url: process.env.REACT_APP_KEYCLOAK_URL || "http://localhost:8080/auth",
  realm: process.env.REACT_APP_KEYCLOAK_REALM || "Bauunternehmung",
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID || "webapp-gestionale",
});

export default keycloak;
