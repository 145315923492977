export const getFirstDay = () => {
  const date = new Date(),
    y = date.getFullYear(),
    m = date.getMonth();
  return new Date(y, m, 1);
};
export const getLastDay = () => {
  const date = new Date(),
    y = date.getFullYear(),
    m = date.getMonth();
  return new Date(y, m + 1, 0);
};
