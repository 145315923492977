import * as React from "react";
import { Route, Redirect, RouteComponentProps } from "react-router-dom";
import type { RouteProps } from "react-router-dom";

import { useKeycloak } from "@react-keycloak/web";

interface PrivateRouteParams extends RouteProps {
  roles?: string[];
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
}

export function PrivateRoute({
  roles,
  component: Component,
  ...rest
}: PrivateRouteParams) {
  const { keycloak } = useKeycloak();

  const isAuthorized = () => {
    if (keycloak && keycloak.authenticated && roles) {
      return roles.some((r: any) => {
        const realm = keycloak.hasRealmRole(r);
        const resource = keycloak.hasResourceRole(r);
        return realm || resource;
      });
    }
    return false;
  };

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthorized() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}
