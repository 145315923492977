import { Punch, Reader } from "../types";

export const toReader = (data: any): Reader => ({
  code: data.reader_code,
  description: data.description,
});

export const toPunch = (data: any): Punch => ({
  id: data.id,
  idBadge: data.id_badge,
  punchDateIn: new Date(data.punch_date_in),
  punchDateOut: data.punch_date_out ? new Date(data.punch_date_out) : null,
  punchHourFIn: data.punch_hour_f_in ? parseInt(data.punch_hour_f_in) : 0,
  punchHourVIn: data.punch_hour_v_in ? parseInt(data.punch_hour_v_in) : 0,
  punchHourFOut: data.punch_hour_f_in ? parseInt(data.punch_hour_f_out) : 0,
  punchHourVOut: data.punch_hour_v_in ? parseInt(data.punch_hour_v_out) : 0,
  readerCodeId: data.reader_code_id,
  reader: data.reader_code ? toReader(data.reader_code) : data.reader_code_id,
  note: data.note || "",
});
